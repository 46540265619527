define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-activity-bottom/high-impact-list-link", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/d-icon", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _dIcon, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HighImpactListLink extends _component.default {
    static shouldRender(outletArgs, _ref) {
      let {
        currentUser,
        siteSettings,
        site
      } = _ref;
      return (!site.mvp_features_restricted || currentUser.can_see_mvp_features) && siteSettings.mvp_high_impact_activity_category !== "";
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li class="user-nav__activity-high-impact">
          <LinkTo @route="userActivity.high-impact">
            {{dIcon "medal"}}
            {{I18n.t "discourse_uipath_mvp.user_activity.nav_text"}}
          </LinkTo>
        </li>
      
    */
    {
      "id": "zEp+rpZA",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"user-nav__activity-high-impact\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@route\"],[\"userActivity.high-impact\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"medal\"],null]],[1,\"\\n        \"],[1,[28,[32,2,[\"t\"]],[\"discourse_uipath_mvp.user_activity.nav_text\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/user-activity-bottom/high-impact-list-link.js",
      "scope": () => [_routing.LinkTo, _dIcon.default, _I18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HighImpactListLink;
});