define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-high-impact-activity-tag-nav", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/routing", "@ember/service", "@ember/string", "discourse/components/horizontal-overflow-nav", "discourse/lib/ajax", "discourse/models/category", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _routing, _service, _string, _horizontalOverflowNav, _ajax, _category, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function titleize(name) {
    return name.split("-").map(word => (0, _string.capitalize)(word)).join(" ");
  }
  class MvpHighImpactActivityTagNav extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "tags", [_tracking.tracked]))();
    #tags = (() => (dt7948.i(this, "tags"), void 0))();
    async loadTags() {
      const category = await _category.default.findById(this.siteSettings.mvp_high_impact_activity_category);
      const allowedTagGroups = category.allowed_tag_groups;
      const defaultTag = {
        id: "all",
        name: "all"
      };
      if (allowedTagGroups.length > 0) {
        const {
          results
        } = await (0, _ajax.ajax)(`/tag_groups/filter/search`, {
          data: {
            names: allowedTagGroups
          }
        });
        const tagsList = results.flatMap(tagGroup => tagGroup.tag_names.map(name => ({
          id: name,
          name
        })));
        if (tagsList) {
          this.tags = [defaultTag, ...tagsList];
        }
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "loadTags", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{didInsert this.loadTags}}>
          {{#if this.tags}}
            <div class="user-navigation user-navigation-secondary tag-group-nav">
              <HorizontalOverflowNav @ariaLabel="User Activty - High Impact Tags">
                {{#each this.tags as |tag|}}
                  <li>
                    <LinkTo
                      @route="userActivity.high-impact"
                      @query={{hash tag=tag.name}}
                    >
                      {{titleize tag.name}}
                    </LinkTo>
                  </li>
                {{/each}}
              </HorizontalOverflowNav>
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "XZGXSogQ",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[32,0],[[30,0,[\"loadTags\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"tags\"]],[[[1,\"        \"],[10,0],[14,0,\"user-navigation user-navigation-secondary tag-group-nav\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@ariaLabel\"],[\"User Activty - High Impact Tags\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"tags\"]]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[12],[1,\"\\n                \"],[8,[32,2],null,[[\"@route\",\"@query\"],[\"userActivity.high-impact\",[28,[32,3],null,[[\"tag\"],[[30,1,[\"name\"]]]]]]],[[\"default\"],[[[[1,\"\\n                  \"],[1,[28,[32,4],[[30,1,[\"name\"]]],null]],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[1]],null],[1,\"          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"tag\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-high-impact-activity-tag-nav.js",
      "scope": () => [_didInsert.default, _horizontalOverflowNav.default, _routing.LinkTo, _helper.hash, titleize],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpHighImpactActivityTagNav;
});