define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-filter-category", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "discourse/components/d-button", "discourse-common/helpers/d-icon", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _helper, _modifier, _object, _didInsert, _dButton, _dIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpFilterCategory extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "displayOptions", [_tracking.tracked], function () {
      return false;
    }))();
    #displayOptions = (() => (dt7948.i(this, "displayOptions"), void 0))();
    get headerIcon() {
      return this.displayOptions ? "angle-down" : "angle-right";
    }
    get shouldRender() {
      return this.args.category.options.length > 1;
    }
    contains(list, item) {
      return list.includes(item);
    }
    optionName(option) {
      return option.label || option;
    }
    optionValue(option) {
      return option.value || option;
    }
    toggleOptionsDisplay() {
      this.displayOptions = !this.displayOptions;
    }
    static #_2 = (() => dt7948.n(this.prototype, "toggleOptionsDisplay", [_object.action]))();
    setOptionsDisplayToggleState() {
      if (this.args.selectedOptions.length > 0) {
        this.displayOptions = true;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "setOptionsDisplayToggleState", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div
            class="mvp-directory-filter-section__item-wrapper"
            {{didInsert this.setOptionsDisplayToggleState}}
          >
            <div class="mvp-directory-filter-section__header-wrapper">
              <DButton
                @title="discourse_uipath_mvp.mvp.filter.toggle_category"
                @action={{this.toggleOptionsDisplay}}
                aria-expanded={{if this.displayOptions "true" "false"}}
                class="btn-flat mvp-directory-filter-section__header"
              >
                <span class="mvp-directory-filter-section__header-caret">
                  {{dIcon this.headerIcon}}
                </span>
                {{@category.name}}
              </DButton>
            </div>
            {{#if this.displayOptions}}
              <ul class="mvp-directory-filter-content">
                {{#each @category.options as |option|}}
                  <li class="mvp-directory-filter-option">
                    <label class="checkbox-label">
                      <Input
                        @type="checkbox"
                        @checked={{this.contains
                          @selectedOptions
                          (this.optionValue option)
                        }}
                        {{on
                          "click"
                          (fn
                            @onChange @category.paramKey (this.optionValue option)
                          )
                        }}
                      />
                      {{this.optionName option}}
                    </label>
                  </li>
                {{/each}}
              </ul>
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "rj2X0aRp",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[11,0],[24,0,\"mvp-directory-filter-section__item-wrapper\"],[4,[32,0],[[30,0,[\"setOptionsDisplayToggleState\"]]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"mvp-directory-filter-section__header-wrapper\"],[12],[1,\"\\n          \"],[8,[32,1],[[16,\"aria-expanded\",[52,[30,0,[\"displayOptions\"]],\"true\",\"false\"]],[24,0,\"btn-flat mvp-directory-filter-section__header\"]],[[\"@title\",\"@action\"],[\"discourse_uipath_mvp.mvp.filter.toggle_category\",[30,0,[\"toggleOptionsDisplay\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[10,1],[14,0,\"mvp-directory-filter-section__header-caret\"],[12],[1,\"\\n              \"],[1,[28,[32,2],[[30,0,[\"headerIcon\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"displayOptions\"]],[[[1,\"          \"],[10,\"ul\"],[14,0,\"mvp-directory-filter-content\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"options\"]]],null]],null],null,[[[1,\"              \"],[10,\"li\"],[14,0,\"mvp-directory-filter-option\"],[12],[1,\"\\n                \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n                  \"],[8,[32,3],[[4,[32,4],[\"click\",[28,[32,5],[[30,4],[30,1,[\"paramKey\"]],[28,[30,0,[\"optionValue\"]],[[30,2]],null]],null]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[28,[30,0,[\"contains\"]],[[30,3],[28,[30,0,[\"optionValue\"]],[[30,2]],null]],null]]],null],[1,\"\\n                  \"],[1,[28,[30,0,[\"optionName\"]],[[30,2]],null]],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[2]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@category\",\"option\",\"@selectedOptions\",\"@onChange\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-filter-category.js",
      "scope": () => [_didInsert.default, _dButton.default, _dIcon.default, _component2.Input, _modifier.on, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpFilterCategory;
});