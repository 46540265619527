define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/below-user-summary-stats/mvp-summaries", ["exports", "@glimmer/component", "@ember/service", "discourse/components/user-summary-section", "discourse/components/user-summary-topics-list", "I18n", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-community-activity-summary", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-summary-award", "discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-user-summary-topic", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _userSummarySection, _userSummaryTopicsList, _I18n, _mvpCommunityActivitySummary, _mvpSummaryAward, _mvpUserSummaryTopic, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpSummaries extends _component.default {
    static shouldRender(_, _ref) {
      let {
        currentUser,
        site
      } = _ref;
      return !site.mvp_features_restricted || currentUser?.can_see_mvp_features;
    }
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get isUserMvp() {
      return this.args.outletArgs.user.mvp_awards.length > 0;
    }
    get highImpactActivityTopics() {
      return this.args.outletArgs.model.high_impact_activites?.map(t => this.store.createRecord("topic", t));
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="top-section stats-section mvp-stats-section">
          <h3 class="stats-title">{{I18n.t "user.summary.community_activity"}}</h3>
          <MvpCommunityActivitySummary
            @categoryStats={{@outletArgs.model.community_activity_stats}}
            @monthlyRewardStats={{@outletArgs.model.monthly_forum_rewards_stats}}
          />
        </div>
    
        <div class="top-section mvp-journey-and-recent-activities-section">
          {{#if this.isUserMvp}}
            <UserSummarySection
              @title="contribution_journey"
              class="mvp-journey-section pull-left"
            >
              {{#if @outletArgs.user.mvp_awards}}
                <ul>
                  {{#each @outletArgs.user.mvp_awards as |mvpAward|}}
                    <MvpSummaryAward @award={{mvpAward}} />
                  {{/each}}
                </ul>
              {{else}}
                {{I18n.t
                  "discourse_uipath_mvp.user.summary.contribution_journey.none_yet"
                }}
              {{/if}}
            </UserSummarySection>
          {{/if}}
          <UserSummarySection
            @title="high_impact_activity"
            class="topics-section mvp-high-impact-activity-section
              {{if this.isUserMvp 'pull-right'}}"
          >
            <UserSummaryTopicsList
              @type="high-impact"
              @items={{this.highImpactActivityTopics}}
              @user={{@outletArgs.user}}
              as |topic|
            >
              <MvpUserSummaryTopic
                @createdAt={{topic.created_at}}
                @topic={{topic}}
                @likes={{topic.like_count}}
                @url={{topic.url}}
              />
            </UserSummaryTopicsList>
          </UserSummarySection>
        </div>
      
    */
    {
      "id": "kOBgxbYi",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"top-section stats-section mvp-stats-section\"],[12],[1,\"\\n      \"],[10,\"h3\"],[14,0,\"stats-title\"],[12],[1,[28,[32,0,[\"t\"]],[\"user.summary.community_activity\"],null]],[13],[1,\"\\n      \"],[8,[32,1],null,[[\"@categoryStats\",\"@monthlyRewardStats\"],[[30,1,[\"model\",\"community_activity_stats\"]],[30,1,[\"model\",\"monthly_forum_rewards_stats\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"top-section mvp-journey-and-recent-activities-section\"],[12],[1,\"\\n\"],[41,[30,0,[\"isUserMvp\"]],[[[1,\"        \"],[8,[32,2],[[24,0,\"mvp-journey-section pull-left\"]],[[\"@title\"],[\"contribution_journey\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"user\",\"mvp_awards\"]],[[[1,\"            \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1,[\"user\",\"mvp_awards\"]]],null]],null],null,[[[1,\"                \"],[8,[32,3],null,[[\"@award\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,0,[\"t\"]],[\"discourse_uipath_mvp.user.summary.contribution_journey.none_yet\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[32,2],[[16,0,[29,[\"topics-section mvp-high-impact-activity-section\\n          \",[52,[30,0,[\"isUserMvp\"]],\"pull-right\"]]]]],[[\"@title\"],[\"high_impact_activity\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,4],null,[[\"@type\",\"@items\",\"@user\"],[\"high-impact\",[30,0,[\"highImpactActivityTopics\"]],[30,1,[\"user\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,5],null,[[\"@createdAt\",\"@topic\",\"@likes\",\"@url\"],[[30,3,[\"created_at\"]],[30,3],[30,3,[\"like_count\"]],[30,3,[\"url\"]]]],null],[1,\"\\n        \"]],[3]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\",\"mvpAward\",\"topic\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/below-user-summary-stats/mvp-summaries.js",
      "scope": () => [_I18n.default, _mvpCommunityActivitySummary.default, _userSummarySection.default, _mvpSummaryAward.default, _userSummaryTopicsList.default, _mvpUserSummaryTopic.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpSummaries;
});