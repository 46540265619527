define("discourse/plugins/discourse-uipath-mvp/discourse/connectors/after-create-topic-button/high-impact-activity-button", ["exports", "@glimmer/component", "discourse/plugins/discourse-uipath-mvp/discourse/components/create-high-impact-activity-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _createHighImpactActivityButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HighImpactActivityButton extends _component.default {
    static shouldRender(outletArgs, _ref) {
      let {
        siteSettings
      } = _ref;
      return outletArgs.canCreateTopic && siteSettings.mvp_high_impact_activity_category === outletArgs.category?.id?.toString();
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <CreateHighImpactActivityButton
          @disabled={{@outletArgs.createTopicDisabled}}
          @category={{@outletArgs.category}}
          @icon="pencil-alt"
        />
      
    */
    {
      "id": "bqUsvFoM",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@disabled\",\"@category\",\"@icon\"],[[30,1,[\"createTopicDisabled\"]],[30,1,[\"category\"]],\"pencil-alt\"]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/connectors/after-create-topic-button/high-impact-activity-button.js",
      "scope": () => [_createHighImpactActivityButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HighImpactActivityButton;
});