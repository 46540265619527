define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-summary-award", ["exports", "@glimmer/component", "@ember/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpSummaryAward extends _component.default {
    get categoryList() {
      return this.args.award.categories.join(", ");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <li>
          <div class="mvp-journey__year">{{@award.year}}</div>
          <div class="mvp-journey__program">{{@award.program}}</div>
          <div class="mvp-journey__categories">{{this.categoryList}}</div>
        </li>
      
    */
    {
      "id": "pdVKSya/",
      "block": "[[[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mvp-journey__year\"],[12],[1,[30,1,[\"year\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"mvp-journey__program\"],[12],[1,[30,1,[\"program\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"mvp-journey__categories\"],[12],[1,[30,0,[\"categoryList\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@award\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-summary-award.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpSummaryAward;
});