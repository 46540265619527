define("discourse/plugins/discourse-uipath-mvp/discourse/initializers/activity-composer", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-owner", "I18n"], function (_exports, _pluginApi, _getOwner, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isCreatingHighImpactActivity(siteSettings, model) {
    if (parseInt(siteSettings.mvp_high_impact_activity_category, 10) !== model.categoryId) {
      return false;
    }
    const router = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:router");
    return router.currentURL.startsWith("/u/") || router.currentRouteName === "discovery.category";
  }
  var _default = _exports.default = {
    name: "high-impact-activity-composer",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.discourse_uipath_mvp_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.24.0", api => {
        const currentUser = api.getCurrentUser();
        if (currentUser && siteSettings.mvp_high_impact_activity_category !== "") {
          this._customizeComposer(api, siteSettings);
        }
      });
    },
    _customizeComposer(api, siteSettings) {
      api.customizeComposerText({
        actionTitle(model) {
          if (isCreatingHighImpactActivity(siteSettings, model)) {
            return _I18n.default.t("discourse_uipath_mvp.activity_composer.title");
          }
        },
        saveLabel(model) {
          if (isCreatingHighImpactActivity(siteSettings, model)) {
            return "discourse_uipath_mvp.activity_composer.save";
          }
        }
      });

      // Modifying form-template-chooser here instead of d-editor since
      // it's inserted much later and not available on d-editor didInsertElement hook.
      api.modifyClass("component:form-template-chooser", {
        pluginId: "high-impact-activity-composer",
        didInsertElement() {
          this._super(...arguments);
          const categoryChooser = document.querySelector(".d-editor-container .category-chooser.has-selection");
          const miniTagChooser = document.querySelector(".d-editor-container .mini-tag-chooser.has-selection");
          const selectedCategoryId = categoryChooser?.querySelector("summary")?.dataset?.value;
          const selectedTag = miniTagChooser?.querySelector("summary")?.dataset?.value;
          if (!selectedCategoryId) {
            return;
          }
          if (isCreatingHighImpactActivity(siteSettings, {
            categoryId: parseInt(selectedCategoryId, 10)
          })) {
            categoryChooser.classList.add("is-disabled");
            if (this.get("value")) {
              this.set("selectKit.options.disabled", true);
            }
            if (selectedTag) {
              miniTagChooser.classList.add("is-disabled");
            }
          }
        }
      });
    }
  };
});