define("discourse/plugins/discourse-uipath-mvp/discourse/controllers/mvps-index", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/utils", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-uipath-mvp/discourse/routes/mvps-index"], function (_exports, _tracking, _controller, _object, _utils, _ajax, _ajaxError, _mvpsIndex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpsIndexController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "programs", [_tracking.tracked], function () {
      return [];
    }))();
    #programs = (() => (dt7948.i(this, "programs"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "categories", [_tracking.tracked], function () {
      return [];
    }))();
    #categories = (() => (dt7948.i(this, "categories"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "years", [_tracking.tracked], function () {
      return [];
    }))();
    #years = (() => (dt7948.i(this, "years"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "languages", [_tracking.tracked], function () {
      return [];
    }))();
    #languages = (() => (dt7948.i(this, "languages"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "regions", [_tracking.tracked], function () {
      return [];
    }))();
    #regions = (() => (dt7948.i(this, "regions"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "countries", [_tracking.tracked], function () {
      return [];
    }))();
    #countries = (() => (dt7948.i(this, "countries"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "technology_expertise", [_tracking.tracked], function () {
      return [];
    }))();
    #technology_expertise = (() => (dt7948.i(this, "technology_expertise"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "industry_focus", [_tracking.tracked], function () {
      return [];
    }))();
    #industry_focus = (() => (dt7948.i(this, "industry_focus"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "search", [_tracking.tracked], function () {
      return null;
    }))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "isLoadingMore", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoadingMore = (() => (dt7948.i(this, "isLoadingMore"), void 0))();
    static #_12 = (() => dt7948.g(this.prototype, "mvps", [_tracking.tracked], function () {
      return null;
    }))();
    #mvps = (() => (dt7948.i(this, "mvps"), void 0))();
    static #_13 = (() => dt7948.g(this.prototype, "currentParams", [_tracking.tracked], function () {
      return null;
    }))();
    #currentParams = (() => (dt7948.i(this, "currentParams"), void 0))();
    static #_14 = (() => dt7948.g(this.prototype, "nextLoadPath", [_tracking.tracked], function () {
      return null;
    }))();
    #nextLoadPath = (() => (dt7948.i(this, "nextLoadPath"), void 0))();
    static #_15 = (() => dt7948.g(this.prototype, "lastLoadPath", [_tracking.tracked], function () {
      return null;
    }))();
    #lastLoadPath = (() => (dt7948.i(this, "lastLoadPath"), void 0))();
    static #_16 = (() => dt7948.g(this.prototype, "totalMvpCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #totalMvpCount = (() => (dt7948.i(this, "totalMvpCount"), void 0))();
    queryParams = (() => Object.keys(_mvpsIndex.QUERY_PARAMS))();
    get canLoadMore() {
      return this.nextLoadPath !== null;
    }
    get isFiltering() {
      return (0, _utils.isPresent)(this.programs) || (0, _utils.isPresent)(this.categories) || (0, _utils.isPresent)(this.years) || (0, _utils.isPresent)(this.languages) || (0, _utils.isPresent)(this.countries) || (0, _utils.isPresent)(this.technology_expertise) || (0, _utils.isPresent)(this.industry_focus) || (0, _utils.isPresent)(this.search);
    }
    async loadMvps(params) {
      this.isLoading = true;
      const {
        search,
        ...filters
      } = params;
      try {
        const response = await (0, _ajax.ajax)("/mvps.json", {
          data: {
            search,
            filters
          }
        });
        this.mvps = response.mvps;
        this.currentParams = params;
        this.nextLoadPath = response.load_more_mvps;
        this.totalMvpCount = response.extras.mvp_count;
        this.lastLoadPath = null;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.isLoading = false;
      }
    }
    async loadMore() {
      if (!this.isLoadingMore && this.mvps && this.nextLoadPath && this.nextLoadPath !== this.lastLoadPath) {
        this.isLoadingMore = true;
        try {
          const response = await (0, _ajax.ajax)(this.nextLoadPath);
          if (response.mvps.length === 0) {
            this.nextLoadPath = null;
            return;
          }
          this.mvps = this.mvps.concat(response.mvps);
          this.lastLoadPath = this.nextLoadPath;
          this.nextLoadPath = response.load_more_mvps;
        } catch (error) {
          (0, _ajaxError.popupAjaxError)(error);
        } finally {
          this.isLoadingMore = false;
        }
      }
    }
    static #_17 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
    updateFilterSelections(name, value) {
      if (this[name].includes(value)) {
        this[name] = this[name].filter(v => v !== value);
      } else {
        this[name] = [...this[name], value];
      }
    }
    static #_18 = (() => dt7948.n(this.prototype, "updateFilterSelections", [_object.action]))();
    clearAllFilterSelections() {
      this.programs = [];
      this.categories = [];
      this.years = [];
      this.languages = [];
      this.countries = [];
      this.technology_expertise = [];
      this.industry_focus = [];
    }
    static #_19 = (() => dt7948.n(this.prototype, "clearAllFilterSelections", [_object.action]))();
    updateSearchTerm(term) {
      this.search = term;
    }
    static #_20 = (() => dt7948.n(this.prototype, "updateSearchTerm", [_object.action]))();
  }
  _exports.default = MvpsIndexController;
});