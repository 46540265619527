define("discourse/plugins/discourse-uipath-mvp/discourse/initializers/user-profile-location", ["exports", "@ember/object", "discourse/lib/plugin-api"], function (_exports, _object, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "user-profile-location",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.24.0", api => {
        api.modifyClass("controller:preferences/profile", dt7948.p({
          pluginId: "discourse-uipath-mvp",
          save() {
            this.model.returnOriginalLocation = true;
            this._super(...arguments);
            this.model.returnOriginalLocation = false;
            this.model.notifyPropertyChange("location");
          }
        }, [["method", "save", [_object.action]]]));
      });
    }
  };
});