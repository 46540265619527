define("discourse/plugins/discourse-uipath-mvp/discourse/routes/mvps-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.QUERY_PARAMS = void 0;
  const QUERY_PARAMS = _exports.QUERY_PARAMS = {
    programs: {
      refreshModel: true
    },
    categories: {
      refreshModel: true
    },
    years: {
      refreshModel: true
    },
    languages: {
      refreshModel: true
    },
    regions: {
      refreshModel: true
    },
    countries: {
      refreshModel: true
    },
    technology_expertise: {
      refreshModel: true
    },
    industry_focus: {
      refreshModel: true
    },
    search: {
      refreshModel: true
    }
  };
  class MvpsIndexRoute extends _discourse.default {
    queryParams = (() => QUERY_PARAMS)();
    model(params) {
      return params;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.loadMvps(model);
    }
  }
  _exports.default = MvpsIndexRoute;
});