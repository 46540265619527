define("discourse/plugins/discourse-uipath-mvp/discourse/controllers/user-activity-high-impact", ["exports", "discourse/controllers/user-topics-list"], function (_exports, _userTopicsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivityHighImpact extends _userTopicsList.default {
    queryParams = ["tag"];
    tag = "all";
  }
  _exports.default = UserActivityHighImpact;
});