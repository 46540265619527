define("discourse/plugins/discourse-uipath-mvp/discourse/helpers/titleize", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = titleize;
  function titleize(slug) {
    return slug.split(/[\-\s]/).map(word => (0, _string.capitalize)(word)).join(" ");
  }
});