define("discourse/plugins/discourse-uipath-mvp/discourse/controllers/mvps", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/utils", "@ember-compat/tracked-built-ins"], function (_exports, _tracking, _controller, _object, _utils, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MvpsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "mvpsIndex", [_controller.inject]))();
    #mvpsIndex = (() => (dt7948.i(this, "mvpsIndex"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "filterSectionTogglePrompt", [_tracking.tracked], function () {
      return "discourse_uipath_mvp.mvp.filter_section.show";
    }))();
    #filterSectionTogglePrompt = (() => (dt7948.i(this, "filterSectionTogglePrompt"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "selectedFilterSet", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedSet();
    }))();
    #selectedFilterSet = (() => (dt7948.i(this, "selectedFilterSet"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "searchTerm", [_tracking.tracked], function () {
      return null;
    }))();
    #searchTerm = (() => (dt7948.i(this, "searchTerm"), void 0))();
    lastSearchTermFilter = null;
    get totalMvpCount() {
      return this.mvpsIndex.totalMvpCount;
    }
    get isFiltering() {
      return this.selectedFilterSet.size > 0 || this.mvpsIndex.search !== null;
    }
    get currentSelectedFilterOptions() {
      return {
        programs: this.mvpsIndex.programs,
        categories: this.mvpsIndex.categories,
        years: this.mvpsIndex.years,
        languages: this.mvpsIndex.languages,
        regions: this.mvpsIndex.regions,
        countries: this.mvpsIndex.countries,
        technology_expertise: this.mvpsIndex.technology_expertise,
        industry_focus: this.mvpsIndex.industry_focus
      };
    }
    seedSelectedFilterSet(initialSelections) {
      Object.entries(initialSelections).forEach(_ref => {
        let [name, values] = _ref;
        if (!(0, _utils.isPresent)(values)) {
          return;
        }
        if (name === "search") {
          this.searchTerm = values;
          this.selectedFilterSet.add(`search|${values}`);
        } else {
          values.forEach(value => this.selectedFilterSet.add(`${name}|${value}`));
        }
      });
    }
    updateFilterSelections(name, value) {
      this.mvpsIndex.updateFilterSelections(name, value);
      if (this.mvpsIndex[name].includes(value)) {
        this.selectedFilterSet.add(`${name}|${value}`);
      } else {
        this.selectedFilterSet.delete(`${name}|${value}`);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "updateFilterSelections", [_object.action]))();
    clearAllFilterSelections() {
      this.mvpsIndex.clearAllFilterSelections();
      this.selectedFilterSet.clear();
      this.searchTerm = null;
      this.mvpsIndex.updateSearchTerm(null);
    }
    static #_6 = (() => dt7948.n(this.prototype, "clearAllFilterSelections", [_object.action]))();
    updateSearchTerm() {
      if (this.searchTerm) {
        this.lastSearchTermFilter = `search|${this.searchTerm}`;
        this.selectedFilterSet.add(this.lastSearchTermFilter);
        this.mvpsIndex.updateSearchTerm(this.searchTerm);
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "updateSearchTerm", [_object.action]))();
    onKeyDown(event) {
      if (event.key === "Enter") {
        this.updateSearchTerm();
        event.preventDefault();
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "onKeyDown", [_object.action]))();
    onKeyUp(event) {
      if (event.key === "Backspace") {
        if (!event.target.value) {
          this.selectedFilterSet.delete(this.lastSearchTermFilter);
          this.mvpsIndex.updateSearchTerm(null);
          this.lastSearchTermFilter = null;
          event.preventDefault();
        }
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "onKeyUp", [_object.action]))();
    toggleFilterSection() {
      const filterSection = document.querySelector(".mvp-directory-filter-section");
      if (filterSection.style.display === "none" || filterSection.style.display === "") {
        filterSection.style.display = "flex";
        this.filterSectionTogglePrompt = "discourse_uipath_mvp.mvp.filter_section.hide";
      } else {
        filterSection.style.display = "none";
        this.filterSectionTogglePrompt = "discourse_uipath_mvp.mvp.filter_section.show";
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "toggleFilterSection", [_object.action]))();
    removeFilterSelection(selection) {
      const [name, value] = selection.split("|");
      this.selectedFilterSet.delete(selection);
      if (name === "search") {
        this.searchTerm = null;
        this.mvpsIndex.updateSearchTerm(null);
      } else {
        this.mvpsIndex.updateFilterSelections(name, value);
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "removeFilterSelection", [_object.action]))();
  }
  _exports.default = MvpsController;
});