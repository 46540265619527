define("discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-profile-field", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/lib/helpers", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _helpers, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIELD_MAP = {
    mvp_current_program: "programs",
    mvp_current_categories: "categories",
    mvp_technology_expertises: "technology_expertise",
    mvp_industry_focuses: "industry_focus"
  };
  class MvpProfileField extends _component.default {
    get query() {
      const queryParamName = FIELD_MAP[this.args.field.key];
      return {
        [queryParamName]: (0, _helpers.makeArray)(this.args.field.value)
      };
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="public-user-field mvp-profile-field">
          <span class="user-field-name">
            <LinkTo @route="mvps" @query={{this.query}}>
              {{@field.name}}:
            </LinkTo>
          </span>
          <span class="user-field-value">
            {{#each @field.value as |v|}}
              <span class="user-field-value-list-item">{{v}}</span>
            {{else}}
              {{@field.value}}
            {{/each}}
          </span>
        </div>
      
    */
    {
      "id": "pBhD0Grv",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"public-user-field mvp-profile-field\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"user-field-name\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@route\",\"@query\"],[\"mvps\",[30,0,[\"query\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"name\"]]],[1,\":\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"user-field-value\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"value\"]]],null]],null],null,[[[1,\"          \"],[10,1],[14,0,\"user-field-value-list-item\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],[[[1,\"          \"],[1,[30,1,[\"value\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@field\",\"v\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-uipath-mvp/discourse/components/mvp-profile-field.js",
      "scope": () => [_routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = MvpProfileField;
});